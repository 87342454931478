import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { makeStyles } from '@material-ui/core';
import React, { useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import TableControls from '../../../../components/ReactDataGrid/TableControls';
import { REACT_DATA_GRID_LICENSE_KEY } from '../../../../constants/constants';
import { logError } from '../../../../services/log';
import { selectOrg } from '../../../org/org.slice';
import { useTableColumns } from '../../hooks/useTableColumns';
import * as userAnalysisCalls from '../../userAnalysis.api';
import { selectUserAnalysis, toggleUserAnalysisColumnVisibility } from '../../userAnalysis.slice';
import FilterBarComponent from '../FiltersBarComponent';
import { formatBiteData, formatDataSource, formatPlaylistData } from '../utils/formatDataForTable';

export enum ContentType {
  BITE = 'Bite',
  PLAYLIST = 'Playlist',
  QUIZ = 'Quiz',
}

const style = { minHeight: window.innerHeight - 250 };
const emptyText = (
  <b
    style={{
      padding: 8,
      border: '1px solid #7986cb',
      color: '#ef9a9a',
      borderRadius: 4,
    }}
  >
    Send bites and analyze your users’ performance/Make sure the date range is relevant for your search
  </b>
);

function UserAnalysisTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const tableRef = useRef();
  const [gridRef, setGridRef] = useState(null);
  const { columnsVisibility, filtersSelected } = useSelector(selectUserAnalysis);
  const org = useSelector(selectOrg);
  const [dataCount, setDataCount] = useState(0);

  const getLastRefreshTime = useCallback(() => {
    const lastCurrentDate = new Date();
    const lastRoundedMinutes = Math.floor(lastCurrentDate.getMinutes() / 10) * 10;
    lastCurrentDate.setMinutes(lastRoundedMinutes);
    const lastTimeString = lastCurrentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return lastTimeString;
  }, []);

  const getNextRefreshTime = useCallback(() => {
    const nextCurrentDate = new Date();
    const currentMinutes = nextCurrentDate.getMinutes();
    let nextRoundedMinutes;

    if (currentMinutes % 10 === 0) {
      // Current time is already at a 10-minute interval
      nextRoundedMinutes = currentMinutes + 10;
    } else {
      // Round up to the next 10-minute interval
      nextRoundedMinutes = Math.ceil(currentMinutes / 10) * 10;
    }

    nextCurrentDate.setMinutes(nextRoundedMinutes);
    const nextTimeString = nextCurrentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return nextTimeString;
  }, []);

  const collapseNodes = useCallback(() => {
    if (tableRef.current) {
      gridRef?.current.collapseAllTreeNodes();
    }
  }, [gridRef]);

  const handleOnTableReady = useCallback((ref) => {
    tableRef.current = ref;
  }, []);

  const handleToggleColumnVisibility = useCallback(
    (columnName) => {
      dispatch(toggleUserAnalysisColumnVisibility(columnName));
    },
    [dispatch],
  );

  const dataSource = useCallback(
    async (tableData) => {
      try {
        const { data } = await userAnalysisCalls.getUsersAggregation({
          filters: {
            user_organization_ids: filtersSelected.userOrganizations.map((user_org) => user_org.userOrganizationId),
            data1_ids: filtersSelected.data1.map((data1) => data1.id),
            data2_ids: filtersSelected.data2.map((data2) => data2.id),
            data3_ids: filtersSelected.data3.map((data3) => data3.id),
            data4_ids: filtersSelected.data4.map((data4) => data4.id),
            data5_ids: filtersSelected.data5.map((data5) => data5.id),
            data6_ids: filtersSelected.data6.map((data6) => data6.id),
            data7_ids: filtersSelected.data7.map((data7) => data7.id),
            data8_ids: filtersSelected.data8.map((data8) => data8.id),
            organization: org.id,
            start_date: new Date(filtersSelected.dateRange.start),
            end_date: new Date(filtersSelected.dateRange.end),
          },
          pagination: true,
          page: tableData.skip / tableData.limit + 1,
          page_size: tableData.limit,
        });
        // TO DO: fix interface
        // @ts-ignore
        const formattedDataToTable = formatDataSource(data.results);

        setDataCount(data.count);
        return { data: formattedDataToTable, count: data.count };
      } catch (error) {
        logError(error);
        toast.error(error.message);
      }
    },
    /* eslint-disable */
    [filtersSelected, org.id, dataCount],
  );
  const columns = useTableColumns(columnsVisibility, org.datas);

  const loadNode = useCallback(
    async ({ node, nodeProps }) => {
      switch (node.content) {
        case ContentType.BITE:
          try {
            const { data } = await userAnalysisCalls.getUserBitesData({
              user_org_id: nodeProps.parentNodeId,
              start_date: new Date(filtersSelected.dateRange.start),
              end_date: new Date(filtersSelected.dateRange.end),
              timezone_offset: new Date().getTimezoneOffset(),
            });
            return formatBiteData(data['stats']);
          } catch (error) {
            logError(error);
            toast.error(error.message);
          }
          break;
        case ContentType.PLAYLIST:
          try {
            const { data } = await userAnalysisCalls.getUserPlaylistsData({
              user_org_id: nodeProps.parentNodeId,
              start_date: new Date(filtersSelected.dateRange.start),
              end_date: new Date(filtersSelected.dateRange.end),
              timezone_offset: new Date().getTimezoneOffset(),
            });
            return formatPlaylistData(data['stats']);
          } catch (error) {
            logError(error);
            toast.error(error.message);
          }
          break;
        case ContentType.QUIZ:
          try {
            const { data } = await userAnalysisCalls.getUserQuizzesData({
              user_org_id: nodeProps.parentNodeId,
              start_date: new Date(filtersSelected.dateRange.start),
              end_date: new Date(filtersSelected.dateRange.end),
              timezone_offset: new Date().getTimezoneOffset(),
            });
            return formatPlaylistData(data['stats']);
          } catch (error) {
            logError(error);
            toast.error(error.message);
          }
          break;
      }
    },
    [filtersSelected.dateRange],
  );

  return (
    <div className={classes.root}>
      <S.RefreshTimeBorder>
        <S.RefreshTimeText>{t('userAnalysis.lastRefreshed') + getLastRefreshTime()}</S.RefreshTimeText>
        <S.RefreshTimeText>{t('userAnalysis.nextRefreshed') + getNextRefreshTime()}</S.RefreshTimeText>
      </S.RefreshTimeBorder>
      <TableControls
        searchText={searchText}
        onSearchTextChange={setSearchText}
        dataSource={dataSource}
        gridRef={tableRef}
        origin='user_analysis'
        columnsVisibility={columnsVisibility}
        onToggleColumnVisibility={handleToggleColumnVisibility}
        columns={columns}
        disableExpansion={true}
        disableExportToExcel={true}
        disableSearchText={true}
      />
      <FilterBarComponent onFilter={collapseNodes} />
      <ReactDataGrid
        handle={setGridRef}
        treeColumn='content'
        loadNodeOnce={loadNode}
        onReady={handleOnTableReady}
        pagination
        defaultLimit={25}
        emptyText={emptyText}
        style={style}
        columns={columns}
        dataSource={dataSource}
        licenseKey={REACT_DATA_GRID_LICENSE_KEY}
      />
    </div>
  );
}

const S = {
  RefreshTimeText: styled.div``,
  RefreshTimeBorder: styled.div`
    border: 1px solid #aca9c7;
    border-radius: 5px;
    padding: 10px;
    width: 200px;
  `,
};

const useStyles = makeStyles({
  root: {
    padding: '20px',
  },
});

export default UserAnalysisTable;
