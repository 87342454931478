import { AxiosResponse } from 'axios';
import capitalize from 'lodash/capitalize';
import moment from 'moment/moment';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  getEmailTemplates,
  getSmsTemplates,
  getWhatsAppTemplates,
  IShareWithIntegrationResponse,
  notifyDistributionCreated,
  notifyScheduledDistributionCreated,
  scheduleDistributionAllUsers,
  scheduleDistributionUnviewedUsers,
  shareWithIntegration,
  TShareTemplates,
} from '../../app/integration.api';
import {
  BEEKEEPER_DEFAULT_MESSAGE_TEXT,
  SLACK_DEFAULT_MESSAGE_TEXT,
  TEAMS_DEFAULT_MESSAGE_TEXT,
} from '../../constants/constants';
import { selectOrg } from '../../features/org/org.slice';
import { datadogLogger } from '../../services/datadog/datadog';
import { IEntityType } from '../../types/entity';
import { EIntegrations, IIntegrationType, TAllowedDMShareIntegrations } from '../../types/integration';
import { getUserLocale, getUserUtcOffsetInHours } from '../../utils/utils';
import PrimaryButton from '../Buttons/PrimaryButton';

import ChooseGroupsModal, { ISelectedGroupsIds, IEveryoneVsCustom } from './ChooseGroupsModal';
import ChooseIntegrationModal from './ChooseIntegrationModal';
import { useDistributionReminderDealy } from './hooks/useDistributionReminderDelay';
import useGetGroupsIdsByDatas from './hooks/useGetGroupsIdsByDatas';
import ShareWithDMModal from './ShareWithDMModal';
import { IOnShareWithSMSArgs, IOnShareWithWhatsappArgs } from './ShareWithDMModal/ShareWithDMModal';
import { TShareWithDMConfig } from './types';
import useMailMerge from './useMailMerge';

interface IProps {
  contentId: number;
  subject: string;
  type: IEntityType;
  shareablePath: string;
  handleCopyLink: () => void;
  onShareSuccessfuly: () => void;
  usersCount: number;
  shareableCoverUrl?: string;
}

function ShareButtonAndManager({
  contentId,
  subject,
  type,
  shareablePath,
  handleCopyLink,
  onShareSuccessfuly,
  usersCount,
  shareableCoverUrl,
}: IProps) {
  const { t } = useTranslation();
  const org = useSelector(selectOrg);
  const [isChooseIntegrationModalOpen, setIsChooseIntegrationModalOpen] = useState(false);
  const [isGroupsModalContinueLoading, setIsGroupsModalContinueLoading] = useState(false);
  const [isChooseGroupsModalOpen, setIsChooseGroupsModalOpen] = useState(false);

  const [isShareWithIntegrationOpen, setIsShareWithIntegrationOpen] = useState(false);
  const [selectedGroupsIds, setSelectedGroupsIds] = useState<ISelectedGroupsIds>([]);
  const [everyoneVsCustom, setEveryoneVsCustom] = useState<IEveryoneVsCustom>('everyone');
  const [modelTemplate, setModelTemplate] = useState<
    undefined | { sms: TShareTemplates; whatsapp: TShareTemplates; email: TShareTemplates }
  >();
  const [integration, setIntegration] = useState<IIntegrationType>('' as IIntegrationType);
  const getGroupsIdsByDatas = useGetGroupsIdsByDatas();
  const { delay } = useDistributionReminderDealy({ integration });

  const { handleShareMailMerge } = useMailMerge({ itemId: contentId });

  const onCloseModal = useCallback(() => {
    setIsShareWithIntegrationOpen(false);
    setIntegration(null);
  }, []);

  const sendedUsersOrGroupsCount = useMemo(() => {
    if (everyoneVsCustom === 'custom') {
      return t('share.noStatsModal.shareWithGroup', { count: selectedGroupsIds.length });
    }
    return t('share.noStatsModal.shareWithUsers', { count: usersCount });
  }, [t, everyoneVsCustom, usersCount, selectedGroupsIds]);

  const handleChooseIntegration = useCallback(async (_integration: IIntegrationType) => {
    setIntegration(_integration);

    setIsChooseIntegrationModalOpen(false);
    setIsChooseGroupsModalOpen(true);
  }, []);

  const handleChooseGroups = useCallback(
    (_selectedGroupsIds: ISelectedGroupsIds) => {
      setSelectedGroupsIds(_selectedGroupsIds);
      if (
        [
          EIntegrations.Beekeeper,
          EIntegrations.Email,
          EIntegrations.WhatsApp,
          EIntegrations.SMS,
          EIntegrations.Teams,
          EIntegrations.Slack,
        ].includes(integration)
      ) {
        setIsChooseGroupsModalOpen(false);
        setIsShareWithIntegrationOpen(true);
      } else if (integration === EIntegrations.Mail_merge) {
        const groupsIdsByDatas = getGroupsIdsByDatas({ selectedGroupsIds, everyoneVsCustom });

        handleShareMailMerge({
          biteShare: type === 'bite' ? contentId : undefined,
          playlist: type === 'playlist' || type === 'quiz' ? contentId : undefined,
          groupsIdsByDatas,
          onLoadingChange: setIsGroupsModalContinueLoading,
          onSuccess: () => setIsChooseGroupsModalOpen(false),
        });
      }
    },
    [integration, type, contentId, everyoneVsCustom, handleShareMailMerge, selectedGroupsIds, getGroupsIdsByDatas],
  );

  const handleShareWithDM = useCallback(
    async (args: IOnShareWithSMSArgs | IOnShareWithWhatsappArgs) => {
      const { sendLater, sendLaterDateTime, sendReminder, setIsSendButtonDisabled, integrationType, message } = args;

      const payload = {
        customReplyMessage: message,
        template_id: args.templateId,
        integration: integrationType,
        ...getGroupsIdsByDatas({ selectedGroupsIds, everyoneVsCustom }),
        users: [],
        biteShare: type === 'bite' ? contentId : undefined,
        playlist: type === 'playlist' || type === 'quiz' ? contentId : undefined,
        orgId: org.id,
      };
      let notificationMessageId: number;
      let scheduledDistributionId: number;
      let reminderDistributionId: number;
      let shareSucceeded = false;
      try {
        setIsSendButtonDisabled(true);
        if (sendLater) {
          if (moment().isAfter(sendLaterDateTime)) {
            throw new Error('Scheduled time must be in the future!');
          }
          const sendAt = sendLaterDateTime.toISOString();
          const scheduleDistributionRes = await scheduleDistributionAllUsers({ sendAt, distParams: payload });
          scheduledDistributionId = scheduleDistributionRes?.data?.distributionId;
          toast(t('share.noStatsModal.successfullyScheduled', { entity: type, time: sendLaterDateTime.fromNow() }));
        } else {
          const shareWithIntegrationRes = await shareWithIntegration(payload);
          notificationMessageId = (shareWithIntegrationRes as AxiosResponse<IShareWithIntegrationResponse>)?.data
            ?.notificationMessageId;
          onShareSuccessfuly();
        }

        onCloseModal();
        shareSucceeded = true;
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsSendButtonDisabled(false);
      }

      if (shareSucceeded && sendReminder) {
        try {
          const initialMsgTime = sendLater ? sendLaterDateTime : moment();
          const reminderTime = initialMsgTime.add(delay, 'hours');
          const reminderRes = await scheduleDistributionUnviewedUsers({
            sendAt: reminderTime.toISOString(),
            distParams: payload,
          });
          reminderDistributionId = reminderRes?.data?.distributionId;
          toast(t('share.noStatsModal.reminder', { time: capitalize(type) }));
        } catch (error) {
          toast.error(error.message);
        }
      }
      if (notificationMessageId) {
        try {
          await notifyDistributionCreated({
            notificationMessageId,
            reminderDistributionId,
            timezone: getUserUtcOffsetInHours(),
            locale: getUserLocale(),
          });
        } catch (e) {
          datadogLogger.error('Error notifying distribution created', e);
        }
      }

      if (scheduledDistributionId) {
        try {
          await notifyScheduledDistributionCreated({
            scheduledDistributionId,
            reminderDistributionId,
            timezone: getUserUtcOffsetInHours(),
            locale: getUserLocale(),
          });
        } catch (e) {
          datadogLogger.error('Error notifying scheduled distribution created', e);
        }
      }
    },
    [
      getGroupsIdsByDatas,
      selectedGroupsIds,
      everyoneVsCustom,
      type,
      contentId,
      onShareSuccessfuly,
      onCloseModal,
      t,
      delay,
      org.id,
    ],
  );

  const handleShare = useCallback(() => {
    setIsChooseIntegrationModalOpen(true);
  }, []);

  useEffect(() => {
    // TODO move this to redux
    const fetchTemplatesData = async () => {
      try {
        let smsData: TShareTemplates = [];
        let whatsappData: TShareTemplates = [];
        let emailData: TShareTemplates = [];
        const [whatsappRequest, smsRequest, emailRequest] = await Promise.allSettled([
          getWhatsAppTemplates(org.id),
          getSmsTemplates(org.id),
          getEmailTemplates(org.id),
        ]);

        if (whatsappRequest.status === 'fulfilled') {
          whatsappData = whatsappRequest.value.data;
        }

        if (smsRequest.status === 'fulfilled') {
          smsData = smsRequest.value.data;
        }

        if (emailRequest.status === 'fulfilled') {
          emailData = emailRequest.value.data;
        }

        setModelTemplate({ sms: smsData, whatsapp: whatsappData, email: emailData });
      } catch (error) {
        console.error('Error fetching templates data:', error);
      }
    };

    fetchTemplatesData();
  }, [org.id]);

  const modalConfigs: TShareWithDMConfig = useMemo(
    () => ({
      [EIntegrations.WhatsApp]: { templates: modelTemplate?.whatsapp },
      [EIntegrations.SMS]: { templates: modelTemplate?.sms },
      [EIntegrations.Email]: { templates: modelTemplate?.email, shareableCoverUrl: shareableCoverUrl },
      [EIntegrations.Beekeeper]: { customMessage: BEEKEEPER_DEFAULT_MESSAGE_TEXT },
      [EIntegrations.Slack]: { customMessage: SLACK_DEFAULT_MESSAGE_TEXT },
      [EIntegrations.Teams]: { customMessage: TEAMS_DEFAULT_MESSAGE_TEXT },
    }),
    [modelTemplate, shareableCoverUrl],
  );

  return (
    <>
      <PrimaryButton onClick={handleShare}>Share {type}</PrimaryButton>
      <ChooseIntegrationModal
        isOpen={isChooseIntegrationModalOpen}
        entity={type}
        onClose={() => setIsChooseIntegrationModalOpen(false)}
        onChoose={handleChooseIntegration}
        onCopy={handleCopyLink}
      />
      <ChooseGroupsModal
        isOpen={isChooseGroupsModalOpen}
        onClose={() => setIsChooseGroupsModalOpen(false)}
        onChoose={handleChooseGroups}
        selectedGroupsIds={selectedGroupsIds}
        onSelectedGroupsIdsChange={setSelectedGroupsIds}
        everyoneVsCustom={everyoneVsCustom}
        onEveryoneVsCustomChange={setEveryoneVsCustom}
        usersCount={usersCount}
        integration={integration}
        isContinueButtonLoading={isGroupsModalContinueLoading}
      />

      {integration &&
        Object.keys(modalConfigs).map(
          (key: TAllowedDMShareIntegrations) =>
            integration === key && (
              <ShareWithDMModal
                key={key}
                isOpen={isShareWithIntegrationOpen}
                entity={type}
                integration={integration}
                onClose={onCloseModal}
                sendedUsersOrGroupsText={sendedUsersOrGroupsCount}
                shareableTitle={subject}
                shareablePath={shareablePath}
                onShare={handleShareWithDM}
                templates={modalConfigs[key].templates}
                shareableCoverUrl={modalConfigs[key].shareableCoverUrl}
                customMessage={modalConfigs[key].customMessage}
              />
            ),
        )}
    </>
  );
}

export default ShareButtonAndManager;
