import React, { ReactChildren } from 'react';
import styled, { css } from 'styled-components';

import Loader from '../Share/Loader';

export interface IProps extends React.HTMLProps<HTMLButtonElement> {
  children?: ReactChildren | string | any;
  height?: number;
  width?: number | string;
  inline?: boolean;
  icon?: any;
  inverted?: boolean;
  isLoading?: boolean;
  isTransparent?: boolean;
  className?: string;
  id?: string;
  isBigText?: boolean;
  paddingStart?: number;
  isRed?: boolean;
}

const Button: React.FC<IProps> = ({
  children,
  height = 44,
  inline = false,
  isLoading,
  isTransparent,
  icon: Icon,
  id,
  isBigText,
  paddingStart,
  width,
  isRed,
  ...rest
}) => {
  return (
    <S.Button
      isRed={isRed}
      inline={inline}
      height={height}
      hasIcon={!!Icon}
      isTransparent={isTransparent}
      width={width}
      {...rest}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <S.Text id={`${id}Text`} isBigText={isBigText} paddingStart={paddingStart} height={height} hasIcon={!!Icon}>
            {children}
          </S.Text>
          {Icon && (
            <S.IconWrap height={height}>
              <Icon />
            </S.IconWrap>
          )}
        </>
      )}
    </S.Button>
  );
};

const S: any = {};

const invertedStyle = css`
  ${({ theme }) => css`
    color: ${theme.colors.primaryBlue};
    background-color: #ffffff;
  `}
`;

S.Button = styled.button<{
  disabled?: boolean;
  inverted?: boolean;
  isTransparent: boolean;
  isRed?: boolean;
}>`
  position: relative;

  display: ${({ inline }) => css`
    ${inline ? 'inline-flex' : 'flex'}
  `};
  align-items: center;
  justify-content: flex-end;
  outline: none;
  padding: ${({ hasIcon, height }) => css`
    ${height / 4}px ${hasIcon ? height / 4 : height}px ${height / 4}px ${height}px;
  `};
  ${({ inline, width }) => css`
    ${width ? `width: ${width}` : inline ? '' : 'width: 100%'}
  `};
  height: ${({ height }) => css`
    ${height}px;
  `}
  line-height: ${({ height }) => css`
    ${height}px;
  `};

  border-radius: 29px;
  border-color: #919194;

  ${({ theme, disabled, inverted, isTransparent, isRed }) => css`
    color: ${isTransparent ? '#808080' : '#ffffff'};
    border: ${isTransparent ? '1px solid #919194' : 'none'};
    background-color: ${disabled ? '#AEAEB2' : isTransparent ? 'transparent' : isRed ? theme.colors.red1 : '#1253fa'};
    ${inverted && invertedStyle};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
`;

S.Text = styled.span<{
  isBigText?: boolean;
  isMediumText?: boolean;
  paddingStart?: number;
  height: number;
  hasIcon: boolean;
}>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  width: 100%;
  font-size: ${({ isBigText }) => (isBigText || isBigText === undefined ? '15px' : '12px')};
  letter-spacing: 1.2px;
  white-space: nowrap;

  ${({ theme }) => css`
    font-family: ${theme.fontFamilies.SFProRegular};
  `}
`;

S.IconWrap = styled.div<{
  height: number;
}>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ height }) => css`
    margin-left: ${(height * 3) / 8}px;
  `}
  width: ${({ height }) => css`
    ${height / 2}px;
  `}
  height: ${({ height }) => css`
    ${height / 2}px;
  `}
`;

export default Button;
