import { createSlice } from '@reduxjs/toolkit';

import { IQuizQuestion } from '../../types/quiz';
import { IQuizViewsTableUser } from '../../types/quizTableUser';
import {
  IRdgColumnVisibility,
  IRdgFilters,
  IRdgGrouping,
  IRdgSortInfo,
  ITableDisplay,
} from '../../types/reactDataGrid';
import { IRootState } from '../../types/store';
import {
  initFilterValue,
  mapStringFilter,
  mapNumberFilter,
  initColumnVisibility,
  initGrouping,
  mapSelectFilter,
} from '../../utils/reactDataGrid';
import { formatDatas, isMobile } from '../../utils/utils';

const quizSlice = createSlice({
  name: 'quiz',
  initialState: getInitialState(),
  extraReducers: getExtraReducers(),
  reducers: getReducers(),
});

export const {
  fetchQuizOverview,
  fetchQuizOverviewSuccess,
  fetchQuizOverviewError,
  fetchQuizSettingsSuccess,
  fetchQuizUserView,
  fetchQuizUserViewSuccess,
  fetchQuizUserViewError,
  setQuizAnalysisSelectedQuestionIndex,
  setQuizAnalysisFilterValue,
  toggleQuizViewsByUserColumnVisibility,
  setQuizViewsByUserFilterValue,
  setQuizViewsByUserGrouping,
  setQuizViewsByUserTableDisplay,
  setQuizViewsByUserSortInfo,
  setQuizzesMap,
} = quizSlice.actions;

export default quizSlice.reducer;

export const selectQuiz = (state: IRootState) => state.quiz;

export const quizzesMapSelector = (state: IRootState) => state.quiz.quizzesMap;

function getInitialState() {
  return {
    isFetchingOverview: false,
    isFetchingUserView: false,
    overviewError: '',
    userViewError: '',
    settings: null,
    views: 0,
    answeredCorrectlyPercentage: 0,
    questions: [] as IQuizQuestion[],
    createdBy: '',
    description: '',
    name: '',
    id: -1,
    coverUrl: undefined,
    quizzesMap: {},
    ...getQuizAnalysisInitialState(),
    ...getQuizViewsByUserInitialState(),
  };
}

type IQuizState = ReturnType<typeof getInitialState>;

function getExtraReducers() {
  return {
    'auth/logout': () => getInitialState(),
    'org/fetchOrgSuccess': (state: IQuizState, action) => {
      const datas = formatDatas(action.payload);
      if (datas.length === 0) {
        state.quizViewsByUserTableDisplay = 'drilldown';
      }
      initColumnVisibility('quizViewsByUserColumnsVisibility', isMobile() ? -1 : 2)(state, datas);
      initFilterValue('quizViewsByUserFilterValue')(state, datas);
      initGrouping('quizViewsByUserGrouping')(state, datas);
    },
  };
}

function getReducers() {
  return {
    fetchQuizOverview: (state: IQuizState) => {
      state.isFetchingOverview = true;
      state.overviewError = '';
    },
    fetchQuizOverviewSuccess: (state: IQuizState, action) => {
      const { views, answeredCorrectlyPercentage, questions, createdBy, description, name, id, coverUrl } =
        action.payload;
      state.isFetchingOverview = false;
      state.views = views;
      state.answeredCorrectlyPercentage = answeredCorrectlyPercentage;
      state.questions = questions;
      state.createdBy = createdBy;
      state.description = description;
      state.name = name;
      state.id = id;
      state.quizAnalysisSelectedQuestionIndex = 0;
      state.coverUrl = coverUrl;
    },
    fetchQuizOverviewError: (state: IQuizState, action) => {
      state.isFetchingOverview = false;
      state.overviewError = action.payload;
    },
    fetchQuizSettingsSuccess: (state: IQuizState, action) => {
      state.settings = action.payload;
    },
    fetchQuizUserView: (state: IQuizState) => {
      state.isFetchingUserView = true;
      state.userViewError = '';
    },
    fetchQuizUserViewSuccess: (state: IQuizState, action) => {
      state.isFetchingUserView = false;
      state.quizViewsByUser = action.payload.map((user) => ({
        ...user,
        // convert empty datas from null to empty strings to
        // avoid syncfusion grid from crashing
        answeredCorrectly: user.answeredCorrectly === 0 ? '0' : user.answeredCorrectly,
        answered: user.answered === 0 ? '0' : user.answered,
        datas: user.datas.map((data) => data || ''),
      }));
    },
    fetchQuizUserViewError: (state: IQuizState, action) => {
      state.isFetchingUserView = false;
      state.userViewError = action.payload;
    },
    setQuizAnalysisSelectedQuestionIndex: (state: IQuizState, action) => {
      state.quizAnalysisSelectedQuestionIndex = action.payload;
    },
    setQuizAnalysisFilterValue: (state: IQuizState, action) => {
      state.quizAnalysisFilterValue = action.payload;
    },
    toggleQuizViewsByUserColumnVisibility: (state: IQuizState, action) => {
      state.quizViewsByUserColumnsVisibility[action.payload] = !state.quizViewsByUserColumnsVisibility[action.payload];
    },
    setQuizViewsByUserFilterValue: (state: IQuizState, action) => {
      state.quizViewsByUserFilterValue = action.payload;
    },
    setQuizViewsByUserGrouping: (state: IQuizState, action) => {
      state.quizViewsByUserGrouping = action.payload;
    },
    setQuizViewsByUserTableDisplay: (state: IQuizState, action) => {
      state.quizViewsByUserTableDisplay = action.payload;
    },
    setQuizViewsByUserSortInfo: (state: IQuizState, action) => {
      state.quizViewsByUserSortInfo = action.payload;
    },
    setQuizzesMap: (state: IQuizState, action) => {
      state.quizzesMap = { ...state.quizzesMap, ...action.payload };
    },
  };
}

function getQuizAnalysisInitialState() {
  return {
    quizAnalysisSelectedQuestionIndex: 0,
    quizAnalysisFilterValue: [
      ...['views', 'completedPercentage', 'answeredCorrectlyPercentage'].map(mapNumberFilter),
    ] as IRdgFilters,
  };
}

function getQuizViewsByUserInitialState() {
  return {
    quizViewsByUser: [] as IQuizViewsTableUser[],
    quizViewsByUserColumnsVisibility: {
      name: true,
      completed: true,
      answered: !isMobile(),
      answeredCorrectly: true,
      phone: false,
      email: false,
    } as IRdgColumnVisibility,
    quizViewsByUserFilterValue: [
      ...['name'].map(mapStringFilter),
      ...['answered', 'answeredCorrectly'].map(mapNumberFilter),
      ...['completed'].map(mapSelectFilter),
    ] as IRdgFilters,
    quizViewsByUserGrouping: [] as IRdgGrouping,
    quizViewsByUserTableDisplay: 'data1' as ITableDisplay,
    quizViewsByUserSortInfo: {
      name: 'answeredCorrectly',
      type: 'number',
      dir: -1,
    } as IRdgSortInfo,
  };
}
