import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  ListSubheader,
  Typography,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-comment.svg';
import { IFullBite } from '../../../types/bite';
import { biteSharesMapSelector, bitesMapSelector } from '../../bites/bites.slice';
import { playlistsMapSelector } from '../../playlists/playlists.slice';
import { quizzesMapSelector } from '../../quiz/quiz.slice';
import { feedContentSelector } from '../assignmentConfig.selectors';
import { searchFeedContent } from '../assignmentConfig.slice';

const MENU_PROPS = {
  disablePortal: true,
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 300,
    },
  },
  getContentAnchorEl: null,
};

interface IProps {
  index: number;
  assignedContent: { id: number; contentType: string };
  onChange: (value: { id: number; contentType: string }[], index: number) => void;
  onChangeTimeToWatch: (value: string, index: number) => void;
  timeToWatch: string;
  onRemoveSelect: (index: number) => void;
  isDeleteEnabled: boolean;
}

const ContentSelect: React.FC<IProps> = ({
  index,
  assignedContent,
  onChange,
  onChangeTimeToWatch,
  timeToWatch,
  onRemoveSelect,
  isDeleteEnabled,
}) => {
  const { t } = useTranslation();
  const bitesMap = useSelector(bitesMapSelector);
  const biteSharesMap = useSelector(biteSharesMapSelector);
  const playlistsMap = useSelector(playlistsMapSelector);
  const quizzesMap = useSelector(quizzesMapSelector);
  const feedContent = useSelector(feedContentSelector);
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [searchContent, setSearchContent] = useState<IFullBite[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  const content = useMemo(() => {
    return value.length ? searchContent : feedContent;
  }, [feedContent, searchContent, value.length]);

  const debouncedSearch = debounce((search: string) => {
    setSearchContent([]);
    if (!search.trim().length) {
      return;
    }

    setIsSearching(true);
    const onSuccess = (data: IFullBite[]) => {
      setIsSearching(false);
      setSearchContent(data);
    };
    const onError = () => {
      setIsSearching(false);
    };
    dispatch(searchFeedContent({ search: search.trim(), onSuccess, onError }));
  }, 300);

  const handleSearchChange = useCallback((event) => {
    setValue(event.target.value);
    debouncedSearch(event.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStopPropagation = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleRemoveSelect = useCallback(() => {
    onRemoveSelect(index);
  }, [index, onRemoveSelect]);

  const formattedContent = useMemo(
    () =>
      content?.reduce(
        (acc, item) => {
          if (item.contentType === 'bite' || item.contentType === 'biteShare') {
            acc.bite.push(item);
          }
          if (item.contentType === 'playlist' && !item.isQuiz) {
            acc.playlist.push(item);
          }
          if (item.contentType === 'quiz') {
            acc.quiz.push(item);
          }
          return acc;
        },
        {
          bite: [
            ...(value.length
              ? []
              : [
                  ...[bitesMap[assignedContent?.id]].filter(Boolean),
                  ...[biteSharesMap[assignedContent?.id]].filter(Boolean),
                ]),
          ],
          playlist: [...(value.length ? [] : [playlistsMap[assignedContent?.id]].filter(Boolean))],
          quiz: [...(value.length ? [] : [quizzesMap[assignedContent?.id]].filter(Boolean))],
        },
      ),
    [assignedContent?.id, biteSharesMap, bitesMap, content, playlistsMap, quizzesMap, value.length],
  );

  const handleChange = useCallback(
    (event) => {
      if (!event.target.value) {
        return;
      }
      onChange(event.target.value, index);
    },
    [index, onChange],
  );

  const renderValue = useCallback((selected) => {
    return (
      <Box display='flex' flexWrap='wrap'>
        <SelectedContent key={selected.id} value={selected} />
      </Box>
    );
  }, []);

  return (
    <S.Container>
      <S.SelectContainer>
        <S.Select
          disableUnderline
          value={assignedContent}
          onChange={handleChange}
          renderValue={renderValue}
          MenuProps={MENU_PROPS}
        >
          <Box px={2} pb={1}>
            <TextField
              placeholder='Search...'
              fullWidth
              variant='standard'
              onChange={handleSearchChange}
              value={value}
              autoFocus
              onClick={handleStopPropagation}
              onKeyDown={handleStopPropagation}
            />
          </Box>
          {isSearching ? (
            <Box display='flex' alignItems='center' px={2} pb={1}>
              <CircularProgress size={20} />
            </Box>
          ) : (
            Object.keys(formattedContent).map((contentType) =>
              formattedContent[contentType].length
                ? [
                    <S.ListSubheader key={contentType}>
                      <S.Typography variant='subtitle2'>
                        {t(`assignmentConfig.updateConfigModal.${contentType}`)}
                      </S.Typography>
                    </S.ListSubheader>,
                    ...formattedContent[contentType].map((option) => (
                      <MenuItem
                        key={`${contentType}-${option.id}`}
                        value={{ ...option, contentType: contentType === 'bite' ? 'biteShare' : contentType }}
                      >
                        <Box display='flex' alignItems='center'>
                          <Typography>{option.subject || option.name}</Typography>
                        </Box>
                      </MenuItem>
                    )),
                  ]
                : null,
            )
          )}
        </S.Select>

        {isDeleteEnabled && (
          <S.DeleteButton onClick={handleRemoveSelect}>
            <DeleteIcon />
          </S.DeleteButton>
        )}
      </S.SelectContainer>

      {!!assignedContent && <TimeToWatchInput index={index} value={timeToWatch} onChange={onChangeTimeToWatch} />}
    </S.Container>
  );
};

const TimeToWatchInput = ({ index, value, onChange }) => {
  const { t } = useTranslation();
  const handleChange = useCallback(
    (event) => {
      onChange(event.target.value, index);
    },
    [index, onChange],
  );

  return (
    <S.TimeToWatchContainer>
      {t('assignmentConfig.updateConfigModal.timeToWatch')}{' '}
      <S.TimeToWatchInput type='text' value={value} onChange={handleChange} />
    </S.TimeToWatchContainer>
  );
};

const SelectedContent = ({ value }) => {
  const name = value.subject || value.name;
  return <S.SelectedValue>{name}</S.SelectedValue>;
};

const S = {
  SelectContainer: styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
  `,
  Select: styled(Select)`
    width: 100%;
    min-height: 48px;
    border-radius: 10px;
    border: 1px solid #d1d1d6;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 1px 5px 0;
  `,
  MenuItem: styled(MenuItem)``,
  Checkbox: styled(Checkbox)``,
  ListItemText: styled(ListItemText)``,
  ListSubheader: styled(ListSubheader)`
    background-color: ${({ theme }) => theme.colors.white};
  `,
  Label: styled.span`
    font-size: 18px;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
  `,

  TimeToWatchContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin-top: 10px;
  `,

  TimeToWatchInput: styled.input`
    height: 48px;
    width: 150px;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid #d1d1d6;
  `,
  Typography: styled(Typography)`
    color: ${({ theme }) => theme.colors.gray19};
  `,

  SelectedValue: styled(Typography)`
    margin-left: 5px;
  `,

  DeleteButton: styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    cursor: pointer;
  `,
};

export default ContentSelect;
