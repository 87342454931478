import { all } from 'redux-saga/effects';

import appActivitySaga from '../features/appActivity/appActivity.saga';
import askAiReportsSaga from '../features/AskAiReports/askAiReports.saga';
import assignmentConfigSaga from '../features/assignmentConfig/assignmentConfig.saga';
import authSaga from '../features/auth/auth.saga';
import biteSaga from '../features/bite/bite.saga';
import bitesSaga from '../features/bites/bites.saga';
import featureFlagSaga from '../features/featureFlag/featureFlag.saga';
import orgSaga from '../features/org/org.saga';
import overviewSaga from '../features/overview/overview.saga';
import playlistSaga from '../features/playlist/playlist.saga';
import playlistsSaga from '../features/playlists/playlists.saga';
import quizSaga from '../features/quiz/quiz.saga';
import quizzesSaga from '../features/quizzes/quizzes.saga';
import userAnalysisSaga from '../features/userAnalysis/userAnalysis.saga';
import userManagementSaga from '../features/userManagement/userManagement.saga';

export default function* rootSaga() {
  yield all([
    orgSaga(),
    authSaga(),
    overviewSaga(),
    playlistsSaga(),
    quizzesSaga(),
    quizSaga(),
    playlistSaga(),
    bitesSaga(),
    biteSaga(),
    userManagementSaga(),
    userAnalysisSaga(),
    featureFlagSaga(),
    appActivitySaga(),
    askAiReportsSaga(),
    assignmentConfigSaga(),
  ]);
}
