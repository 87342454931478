import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Plus } from '../../../../assets/icons/plus-icon.svg';
import { ReactComponent as SlackIcon } from '../../../../assets/icons/slack.svg';
import { ReactComponent as Sync } from '../../../../assets/icons/sync.svg';
import BlueButton from '../../../../components/Buttons/BlueButton';
import gtmTrack from '../../../../services/googleTagManager/track';
import { Feature } from '../../../../types/feature';
import { SLACK_CLIENT_ID, SLACK_REDIRECT_URI, SLACK_SCOPES } from '../../../../utils/env';
import { selectOrg } from '../../../org/org.slice';
import { bulkBlock, bulkUnblock, bulkResetPassword } from '../../userManagement.slice';
import AddUserModal from '../UploadUsersHelpers/SingleUserModel';
import UploadUsersModal from '../UploadUsersHelpers/SyncUploadModal';
import { S } from '../UploadUsersHelpers/ui';

interface IProps {
  selectedRows: { id: number }[];
}

const buttonStyles = { marginRight: 12, marginBottom: 16 };
const iconSize = 20;

function BulkOperations({ selectedRows }: IProps) {
  const currentOrg = useSelector(selectOrg);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpenModalSyncUsers, setIsOpenModalSyncUsers] = useState(false);
  const [isOpenModalSingleUser, setIsOpenModalSingleUser] = useState(false);

  const getSelectedUserIds = () => selectedRows.map((row) => row.id);

  // ------------ MODAL HANDLERS ------------

  const handleOpenUploadUsersModal = useCallback(() => {
    setIsOpenModalSyncUsers(true);
  }, []);

  const handleOpenAddUserModal = useCallback(() => {
    setIsOpenModalSyncUsers(false);
    setIsOpenModalSingleUser(true);
  }, []);

  const handleCloseModaleUploadUsers = useCallback(() => {
    setIsOpenModalSingleUser(false);
    setIsOpenModalSyncUsers(false);
    // setDisabledUploadUsersButton(false);
  }, []);

  // ------------ MODAL HANDLERS ------------

  const handleBulkBlock = () => {
    const shouldContinue = window.confirm(t('userManagement.table.areYouSure'));
    if (!shouldContinue) {
      return;
    }
    const ids = getSelectedUserIds();
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(bulkBlock(ids));
    // TO DO: fix interface
    // @ts-ignore
    gtmTrack('user_management_block_users', { user_organization_ids: ids });
  };

  const handleBulkUnblock = () => {
    const shouldContinue = window.confirm(t('userManagement.table.areYouSure'));
    if (!shouldContinue) {
      return;
    }
    const ids = getSelectedUserIds();
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(bulkUnblock(ids));
    // TO DO: fix interface
    // @ts-ignore
    gtmTrack('user_management_unblock_users', { user_organization_ids: ids });
  };

  const handleBulkResetPassword = () => {
    const shouldContinue = window.confirm(t('userManagement.table.resetPasswordConfirm'));
    if (!shouldContinue) {
      return;
    }
    const ids = getSelectedUserIds();
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(bulkResetPassword(ids));
    // TO DO: fix interface
    // @ts-ignore
    gtmTrack('user_management_reset_password', { user_organization_ids: ids });
  };

  const isDisabled = () => getSelectedUserIds().length === 0;
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <BlueButton onClick={handleOpenAddUserModal} style={buttonStyles}>
          <S.SyncButtonWrapper>
            <Plus style={{ width: iconSize, height: iconSize, marginRight: '3px' }} />
            {t('userManagement.table.uploadUser')}
          </S.SyncButtonWrapper>
        </BlueButton>
        <BlueButton onClick={handleOpenUploadUsersModal} style={buttonStyles}>
          <S.SyncButtonWrapper>
            <Sync style={{ width: iconSize, height: iconSize, marginRight: '3px' }} />
            {t('userManagement.table.syncUsers')}
          </S.SyncButtonWrapper>
        </BlueButton>
        <BlueButton onClick={handleBulkBlock} disabled={isDisabled()} style={buttonStyles} data-cy='bulkBlock'>
          {t('userManagement.table.blockUsers')}
        </BlueButton>
        <BlueButton onClick={handleBulkUnblock} disabled={isDisabled()} style={buttonStyles} data-cy='bulkUnblock'>
          {t('userManagement.table.unblockUsers')}
        </BlueButton>
        <BlueButton
          onClick={handleBulkResetPassword}
          disabled={isDisabled()}
          style={{ marginBottom: 16 }}
          data-cy='bulkResetPassword'
        >
          {t('userManagement.table.resetPassword')}
        </BlueButton>
        {currentOrg.features?.includes(Feature.SLACK_DISTRIBUTION) &&
          !currentOrg.features.includes(Feature.SLACK_INTEGRATION) && (
            <Button
              variant='outlined'
              color='default'
              rel='noopener'
              target='_blank'
              href={`https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&redirect_uri=${SLACK_REDIRECT_URI}&scope=${SLACK_SCOPES}&user_scope=&state=${currentOrg.id}`}
              startIcon={<SlackIcon />}
              style={{
                height: '40px',
                marginRight: '15px',
                marginLeft: '15px',
                marginBottom: '15px',
                lineHeight: 1.2,
                textAlign: 'center',
              }}
            >
              {t('userManagement.slackIntegration')}
            </Button>
          )}
      </div>
      <UploadUsersModal isVisible={isOpenModalSyncUsers} onClose={handleCloseModaleUploadUsers} />

      <AddUserModal isVisible={isOpenModalSingleUser} onClose={handleCloseModaleUploadUsers} />
    </div>
  );
}

export default BulkOperations;
