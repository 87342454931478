import { useSelector } from 'react-redux';

import { Features } from '../constants/constants';
import { selectActiveUserOrganization } from '../features/auth/auth.selectors';
import { selectOrg } from '../features/org/org.slice';

const useHaveFeatureFlag = ({
  featureFlag,
  withAdminAccess = false,
}: {
  featureFlag: Features;
  withAdminAccess?: boolean;
}) => {
  const currentOrg = useSelector(selectOrg);
  const activeUserOrganization = useSelector(selectActiveUserOrganization);
  const hasFeatureFlag = currentOrg.features?.includes(featureFlag);
  const hasAdminAccess =
    activeUserOrganization?.hasAdminAccess ||
    activeUserOrganization?.hasDashboardAccess ||
    activeUserOrganization?.hasOrganizationAdminAccess;

  return hasFeatureFlag && (!withAdminAccess || hasAdminAccess);
};

export default useHaveFeatureFlag;
