import bitesApi from '../../services/bitesApi';
import { IFullBite } from '../../types/bite';
import { IBiteAnswersTableUser, IBiteViewsTableUser } from '../../types/biteTableUser';
import { IComment } from '../../types/comment';

export const getBite = (id: number) => bitesApi.get<IFullBite>(`/dashboard/bites/${id}/`);

export const getBiteShare = (id: number) => bitesApi.get<IFullBite>(`/common_services/content/bite_share/${id}/`);

export const getComments = (bite: number) => bitesApi.get<IComment[]>(`comments/?bite=${bite}`);

export const getAnswerAnalysis = (biteId: number) =>
  bitesApi.get<IBiteAnswersTableUser[]>(`/dashboard/bites/${biteId}/answers/`);

export const getBiteViewsByUser = (biteId: number) =>
  bitesApi.get<IBiteViewsTableUser[]>(`/dashboard/bites/${biteId}/views/`);

export const deleteComment = ({ commentId }: { commentId: number }) => {
  return bitesApi.del(`/comments/${commentId}/`);
};
