import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TableControls from '../../../../components/ReactDataGrid/TableControls';
import useCheckbox from '../../../../components/ReactDataGrid/useCheckbox';
import useTableUsers from '../../../../components/ReactDataGrid/useTableUsers';
import { REACT_DATA_GRID_LICENSE_KEY } from '../../../../constants/constants';
import { getScrollProps } from '../../../../utils/reactDataGrid';
import {
  selectUserManagement,
  setUserManagementFilterValue,
  setUserManagementGrouping,
  toggleUserManagementColumnVisibility,
} from '../../userManagement.slice';

import BulkOperations from './BulkOperations';
import useColumns from './useColumns';

interface IProps {
  onSelectUser: (id: number) => void;
}

function UserManagementTable({ onSelectUser }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [gridRef, setGridRef] = useState(null);
  const { users, columnsVisibility, filterValue, grouping } = useSelector(selectUserManagement);

  const gridTranslations = useMemo(
    () => ({
      dragHeaderToGroup: t('userManagement.table.dragHeaderToGroup'),
    }),
    [t],
  );

  const columns: TypeColumn[] = useColumns({ onSelectUser });
  const dataSource = useTableUsers({
    searchText,
    users,
    columns,
    filterValue,
  });
  const { checkboxColumn, selectedRowsArray, handleSelectAll } = useCheckbox({
    dataSource,
    withCheckbox: true,
  });

  const footerRows = [
    {
      render: {
        fullName: () => t('userManagement.table.usersLength', { length: users.length }),
      },
    },
  ];

  const renderGroupValue = useCallback(({ value, data }) => `${value} (${data.array.length})`, []);

  const groupToString = (value) => ([null, undefined, ''].includes(value) ? t('userManagement.table.empty') : value);

  return (
    <>
      <BulkOperations selectedRows={selectedRowsArray} />
      <TableControls
        searchText={searchText}
        onSearchTextChange={setSearchText}
        dataSource={dataSource}
        gridRef={gridRef}
        origin='user_management'
        columnsVisibility={columnsVisibility}
        onToggleColumnVisibility={(columnName) => dispatch(toggleUserManagementColumnVisibility(columnName))}
        columns={columns}
        intercomExportTarget='export_users'
      />

      <div data-intercom-target='users_table'>
        <ReactDataGrid
          i18n={gridTranslations}
          idProperty='id'
          scrollProps={getScrollProps()}
          onReady={(ref) => {
            ref.current.collapseAllGroups();
            setGridRef(ref);
          }}
          dataSource={dataSource}
          columns={columns}
          filterValue={filterValue}
          onFilterValueChange={(newFilterValue) => dispatch(setUserManagementFilterValue(newFilterValue))}
          footerRows={footerRows}
          checkboxColumn={checkboxColumn}
          onSelectionChange={handleSelectAll}
          groupBy={grouping}
          onGroupByChange={(groupByValue) => dispatch(setUserManagementGrouping(groupByValue))}
          sortable
          licenseKey={REACT_DATA_GRID_LICENSE_KEY}
          style={{ minHeight: window.innerHeight - 200 }}
          groupColumn={{ renderGroupValue }}
          groupToString={groupToString}
        />
      </div>
    </>
  );
}

export default UserManagementTable;
