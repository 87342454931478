import { Select, MenuItem, Checkbox, ListItemText, Box, ListSubheader, Typography, Chip } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-comment.svg';
import { attributeValuesMapSelector, attributeValuesSelector } from '../assignmentConfig.selectors';

const MENU_PROPS = {
  disablePortal: true,
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 300,
    },
  },
  getContentAnchorEl: null,
};

interface IProps {
  index: number;
  selectedAttributes: any[];
  onChange: (value: number[], index: number) => void;
  onRemoveSelect: (index: number) => void;
  isDeleteEnabled: boolean;
}

const AttributesSelect: React.FC<IProps> = ({
  index,
  selectedAttributes,
  onChange,
  isDeleteEnabled,
  onRemoveSelect,
}) => {
  const attributes = useSelector(attributeValuesSelector);

  const handleChange = useCallback(
    (event) => {
      if (!event.target.value[0]) {
        return;
      }
      onChange(event.target.value, index);
    },
    [index, onChange],
  );

  const handleDelete = useCallback(
    (value) => {
      onChange(
        selectedAttributes.filter((selected) => selected !== value),
        index,
      );
    },
    [index, onChange, selectedAttributes],
  );

  const handleRemoveSelect = useCallback(() => {
    onRemoveSelect(index);
  }, [index, onRemoveSelect]);

  const renderValue = useCallback(
    (selected) => {
      return (
        <Box display='flex' flexWrap='wrap'>
          {selected.map((value) => (
            <SelectedAttribute key={value.id} value={value} onDelete={handleDelete} />
          ))}
        </Box>
      );
    },
    [handleDelete],
  );

  return (
    <S.Container>
      <S.Select
        multiple
        disableUnderline
        value={selectedAttributes}
        onChange={handleChange}
        renderValue={renderValue}
        MenuProps={MENU_PROPS}
      >
        {attributes.map((attributeTitle) => [
          <S.ListSubheader key={attributeTitle.attributesTitleId}>
            <Typography variant='subtitle2' style={{ color: 'gray' }}>
              {attributeTitle.attributesTitle}
            </Typography>
          </S.ListSubheader>,
          ...attributeTitle.attributeValues.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Box display='flex' alignItems='center'>
                <Typography>{option.name}</Typography>
              </Box>
            </MenuItem>
          )),
        ])}
      </S.Select>
      {isDeleteEnabled && (
        <S.DeleteButton onClick={handleRemoveSelect}>
          <DeleteIcon />
        </S.DeleteButton>
      )}
    </S.Container>
  );
};

const SelectedAttribute = ({ value, onDelete }) => {
  const { t } = useTranslation();
  const attributeValuesMap = useSelector(attributeValuesMapSelector);
  const handleMouseDown = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleDelete = useCallback(
    (event) => {
      event.stopPropagation();
      onDelete(value);
    },
    [onDelete, value],
  );

  return (
    <S.Chip
      label={attributeValuesMap[value]?.name || t('assignmentConfig.table.attributeValueNotExists')}
      onDelete={handleDelete}
      onMouseDown={handleMouseDown}
    />
  );
};

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
  `,
  Select: styled(Select)`
    min-height: 50px;
    width: 100%;
    border: 1px solid #d1d1d6;
    border-radius: 10px;
    margin-top: 20px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 1px 5px 0;
  `,
  DeleteButton: styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 18px;
    margin-left: 10px;
    cursor: pointer;
  `,
  MenuItem: styled(MenuItem)``,
  Checkbox: styled(Checkbox)``,
  ListItemText: styled(ListItemText)``,
  Chip: styled(Chip)`
    height: 30px;
    margin: 2px;
  `,
  ListSubheader: styled(ListSubheader)`
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.white};
    margin-top: 10px;
  `,
};

export default AttributesSelect;
