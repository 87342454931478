import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import EllipsisWithTooltip from '../../../components/EllipsisWithTooltip';
import { biteSharesMapSelector, bitesMapSelector } from '../../bites/bites.slice';
import { playlistsMapSelector } from '../../playlists/playlists.slice';
import { quizzesMapSelector } from '../../quiz/quiz.slice';

const ContentLink = ({ content }) => {
  const biteSharesMap = useSelector(biteSharesMapSelector);
  const bitesMap = useSelector(bitesMapSelector);
  const playlistsMap = useSelector(playlistsMapSelector);
  const quizzesMap = useSelector(quizzesMapSelector);

  const renderBiteShareLink = useCallback(
    (biteShareId) => {
      const biteData = biteSharesMap[biteShareId];
      return biteData ? (
        <Link key={`related-${biteData.biteId}`} to={`/bites/${biteData.biteId}`}>
          <EllipsisWithTooltip text={biteData.subject} />
        </Link>
      ) : null;
    },
    [biteSharesMap],
  );

  const renderBiteLink = useCallback(
    (biteId) => {
      const biteData = bitesMap[biteId];
      return biteData ? (
        <Link key={`related-${biteData.id}`} to={`/bites/${biteData.id}`}>
          <EllipsisWithTooltip text={biteData.subject} />
        </Link>
      ) : null;
    },
    [bitesMap],
  );

  const renderPlaylistLink = useCallback(
    (playlistId) => {
      const playlistsData = playlistsMap[playlistId];
      return playlistsData ? (
        <Link key={`related-${playlistsData.id}`} to={`/playlists/${playlistsData.id}`}>
          <EllipsisWithTooltip text={playlistsData.name} />
        </Link>
      ) : null;
    },
    [playlistsMap],
  );

  const renderQuizLink = useCallback(
    (quizId) => {
      const quizData = quizzesMap[quizId];
      return quizData ? (
        <Link key={`related-${quizData.id}`} to={`/quizzes/${quizData.id}`}>
          <EllipsisWithTooltip text={quizData.name} />
        </Link>
      ) : null;
    },
    [quizzesMap],
  );

  if (!content) {
    return null;
  }

  return content.map((contentItem) => {
    if (contentItem.contentType === 'bite') {
      return renderBiteLink(contentItem.contentId);
    } else if (contentItem.contentType === 'biteShare') {
      return renderBiteShareLink(contentItem.contentId);
    } else if (contentItem.contentType === 'playlist') {
      return renderPlaylistLink(contentItem.contentId);
    } else if (contentItem.contentType === 'quiz') {
      return renderQuizLink(contentItem.contentId);
    }
    return null;
  });
};

export default ContentLink;
