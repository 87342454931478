import { TypeColumns } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { makeStyles, TextField, Tooltip } from '@material-ui/core';
import cx from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ReactComponent as AirplaneIcon } from '../../assets/icons/airplane.svg';
import { ReactComponent as CollapseIcon } from '../../assets/icons/collapse.svg';
import { ReactComponent as ExportQuizAnswers } from '../../assets/icons/csv-icon.svg';
import { ReactComponent as ExpandIcon } from '../../assets/icons/expand.svg';
import { selectCanSendMessages } from '../../features/auth/auth.selectors';
import useIsMobile from '../../hooks/useIsMobile';
import gtmTrack from '../../services/googleTagManager/track';
import { IRdgColumnVisibility } from '../../types/reactDataGrid';

import ColumnChooser from './ColumnChooser';
import ExportToExcel from './ExportToExcel';

interface IProps {
  searchText: string;
  onSearchTextChange: (text: string) => void;
  onSend?: () => void;
  sendIconDisabledMessage?: string;
  dataSource: any[] | ((prop: any) => any);
  gridRef: any;
  maxVisibleColumnsOnMobile?: number;
  onToggleColumnVisibility: (columnName: string) => void;
  columnsVisibility: IRdgColumnVisibility;
  columns: TypeColumns;
  origin: string;
  disableExpansion?: boolean;
  disableExportToExcel?: boolean;
  disableSearchText?: boolean;
  intercomExportTarget?: string;
  getExportData?: (dataSource: any[] | ((prop: any) => any)) => void;
}

function TableControls({
  searchText,
  onSearchTextChange,
  onSend,
  sendIconDisabledMessage,
  dataSource,
  gridRef,
  maxVisibleColumnsOnMobile,
  onToggleColumnVisibility,
  columnsVisibility,
  columns,
  origin,
  disableExpansion,
  disableExportToExcel,
  disableSearchText,
  intercomExportTarget,
  getExportData,
}: IProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const canSendMessages = useSelector(selectCanSendMessages);

  const handleSend = () => (sendIconDisabledMessage ? toast(sendIconDisabledMessage) : onSend());

  const handleExpand = () => {
    if (origin === 'user_management') {
      gtmTrack('user_management_expand_groups');
    }

    gridRef?.current.expandAllGroups();
  };

  const handleCollapse = () => {
    gtmTrack('user_management_collapse_groups');
    gridRef?.current.collapseAllGroups();
  };

  const handleGetExportData = useCallback(() => {
    getExportData(dataSource);
  }, [dataSource, getExportData]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: !disableSearchText ? 'space-between' : 'flex-end',
        marginBottom: 10,
      }}
    >
      {!disableSearchText && (
        <TextField
          value={searchText}
          onChange={(evt) => onSearchTextChange(evt.target.value)}
          placeholder={t('share.search')}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {onSend && canSendMessages ? (
          <Tooltip title={t('table.send')}>
            <AirplaneIcon
              className={cx(classes.icon, {
                [classes.isIconDisabled]: sendIconDisabledMessage,
              })}
              onClick={handleSend}
            />
          </Tooltip>
        ) : null}
        {!isMobile && !disableExportToExcel && (
          <>
            {typeof getExportData === 'function' && (
              <Tooltip title={t('table.exportAnswersForQuiz')}>
                <ExportQuizAnswers className={classes.icon} onClick={handleGetExportData} />
              </Tooltip>
            )}
            <Tooltip title={t('table.exportToExcel')}>
              <ExportToExcel
                className={classes.icon}
                gridRef={gridRef}
                dataSource={dataSource}
                origin={origin}
                intercomTarget={intercomExportTarget}
              />
            </Tooltip>
          </>
        )}

        <Tooltip title={t('table.chooseColumns')}>
          <div>
            <ColumnChooser
              iconClassName={classes.icon}
              maxVisibleColumnsOnMobile={maxVisibleColumnsOnMobile}
              onToggle={onToggleColumnVisibility}
              columns={columns}
              columnsVisibility={columnsVisibility}
              origin={origin}
            />
          </div>
        </Tooltip>
        {!isMobile && !disableExpansion && (
          <>
            <Tooltip title={t('table.expandGroups')}>
              <ExpandIcon onClick={handleExpand} className={classes.icon} />
            </Tooltip>
            <Tooltip title={t('table.collapseGroups')}>
              <CollapseIcon onClick={handleCollapse} className={classes.icon} />
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  icon: {
    width: 40,
    height: 40,
    padding: '9px 8px',
    borderRadius: 11,
    boxShadow: '0 2px 3px 0 rgba(49, 89, 165, 0.46)',
    backgroundColor: 'rgba(239, 243, 249, 0.2)',
    fill: '#282828',
    cursor: 'pointer',
    '&:not(last-child)': {
      marginLeft: 12,
    },
  },
  isIconDisabled: {
    opacity: 0.4,
  },
});

export default TableControls;
