import { AxiosResponse } from 'axios';
import capitalize from 'lodash/capitalize';
import size from 'lodash/size';
import moment from 'moment';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  getEmailTemplates,
  getSmsTemplates,
  getWhatsAppTemplates,
  IShareWithIntegrationResponse,
  notifyDistributionCreated,
  notifyScheduledDistributionCreated,
  scheduleDistributionAllUsers,
  scheduleDistributionUnviewedUsers,
  shareWithIntegration,
  TShareTemplates,
} from '../../app/integration.api';
import {
  BEEKEEPER_DEFAULT_MESSAGE_TEXT,
  SLACK_DEFAULT_MESSAGE_TEXT,
  TEAMS_DEFAULT_MESSAGE_TEXT,
} from '../../constants/constants';
import { selectOrg } from '../../features/org/org.slice';
import { datadogLogger } from '../../services/datadog/datadog';
import { IEntityType } from '../../types/entity';
import { EIntegrations, IIntegrationType, TAllowedDMShareIntegrations } from '../../types/integration';
import { getUserLocale, getUserUtcOffsetInHours } from '../../utils/utils';

import ChooseIntegrationModal from './ChooseIntegrationModal';
import ShareWithDMModal from './ShareWithDMModal';
import {
  IOnShareWithBeekeeperArgs,
  IOnShareWithSMSArgs,
  IOnShareWithWhatsappArgs,
} from './ShareWithDMModal/ShareWithDMModal';
import { TShareWithDMConfig } from './types';
import useMailMerge from './useMailMerge';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onShare: () => void;
  selectedRows: Record<'id', number>[];
  entity: IEntityType;
  shareablePath: string;
  shareableTitle: string;
  contentId: number;
  shareableCoverUrl?: string;
}

const ShareFromTableManager: React.FC<IProps> = ({
  isOpen: isChooseIntegrationModalOpen,
  onClose: closeChooseIntegrationModal,
  onShare,
  selectedRows,
  entity,
  shareablePath,
  shareableTitle,
  contentId,
  shareableCoverUrl,
}) => {
  const { t } = useTranslation();
  const { handleShareMailMerge } = useMailMerge({ itemId: contentId });
  const [isMailMergeLoading, setIsMailMergeLoading] = useState(false);
  const [integration, setIntegration] = useState<IIntegrationType | null>(null);
  const [modelTemplate, setModelTemplate] = useState<
    undefined | { sms: TShareTemplates; whatsapp: TShareTemplates; email: TShareTemplates }
  >();
  const [isShareWithIntegrationOpen, setIsShareWithIntegrationOpen] = useState(false);
  const org = useSelector(selectOrg);

  const onCloseModal = useCallback(() => {
    setIsShareWithIntegrationOpen(false);
    setIntegration(null);
  }, []);

  const handleShareWithDM = useCallback(
    async (args: IOnShareWithSMSArgs | IOnShareWithWhatsappArgs | IOnShareWithBeekeeperArgs) => {
      const {
        sendLater,
        sendLaterDateTime,
        sendReminder,
        sendReminderDateTime,
        setIsSendButtonDisabled,
        integrationType,
        message,
      } = args;

      const payload = {
        users: selectedRows.map((row) => row.id).filter((user) => user !== 1),
        template_id: args.templateId,
        integration: integrationType as EIntegrations,
        biteShare: entity === 'bite' ? contentId : undefined,
        playlist: entity === 'playlist' || entity === 'quiz' ? contentId : undefined,
        customReplyMessage: message,
        orgId: org.id,
      };

      let shareSucceeded = false;
      let notificationMessageId: number;
      let scheduledDistributionId: number;
      let reminderDistributionId: number;

      try {
        setIsSendButtonDisabled(true);
        if (sendLater) {
          if (moment().isAfter(sendLaterDateTime)) {
            throw new Error('Scheduled time must be in the future!');
          }
          const sendAt = sendLaterDateTime.toISOString();
          const scheduleDistributionRes = await scheduleDistributionAllUsers({ sendAt, distParams: payload });
          scheduledDistributionId = scheduleDistributionRes?.data?.distributionId;
          toast(t('share.noStatsModal.successfullyScheduled', { entity, time: sendLaterDateTime.fromNow() }));
        } else {
          const shareWithIntegrationRes = await shareWithIntegration(payload);
          notificationMessageId = (shareWithIntegrationRes as AxiosResponse<IShareWithIntegrationResponse>)?.data
            ?.notificationMessageId;
          toast(t('share.noStatsModal.sharedSuccessfully', { entity: capitalize(entity) }));
        }

        closeChooseIntegrationModal();
        onShare();
        onCloseModal();
        shareSucceeded = true;
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsSendButtonDisabled(false);
      }

      if (shareSucceeded && sendReminder) {
        try {
          await scheduleDistributionUnviewedUsers({ sendAt: sendReminderDateTime.toISOString(), distParams: payload });
          toast(t('share.noStatsModal.reminder', { time: capitalize(entity) }));
        } catch (error) {
          toast.error(error.message);
        }
      }

      if (notificationMessageId) {
        try {
          await notifyDistributionCreated({
            notificationMessageId,
            reminderDistributionId,
            timezone: getUserUtcOffsetInHours(),
            locale: getUserLocale(),
          });
        } catch (e) {
          datadogLogger.error('Error notifying distribution created', e);
        }
      }

      if (scheduledDistributionId) {
        try {
          await notifyScheduledDistributionCreated({
            scheduledDistributionId,
            reminderDistributionId,
            timezone: getUserUtcOffsetInHours(),
            locale: getUserLocale(),
          });
        } catch (e) {
          datadogLogger.error('Error notifying scheduled distribution created', e);
        }
      }
    },
    [org.id, selectedRows, entity, contentId, closeChooseIntegrationModal, onShare, onCloseModal, t],
  );

  const handleChooseOption = useCallback(
    async (newIntegration: IIntegrationType) => {
      setIntegration(newIntegration);

      if (
        [
          EIntegrations.SMS,
          EIntegrations.WhatsApp,
          EIntegrations.Email,
          EIntegrations.Beekeeper,
          EIntegrations.Teams,
          EIntegrations.Slack,
        ].includes(newIntegration)
      ) {
        closeChooseIntegrationModal();
        setIsShareWithIntegrationOpen(true);
      } else if (newIntegration === EIntegrations.Mail_merge) {
        handleShareMailMerge({
          biteShare: entity === 'bite' ? contentId : undefined,
          playlist: entity === 'playlist' || entity === 'quiz' ? contentId : undefined,
          users: selectedRows.map((row) => row.id),
          onLoadingChange: setIsMailMergeLoading,
          onSuccess: closeChooseIntegrationModal,
        });
      }
    },
    [contentId, entity, selectedRows, closeChooseIntegrationModal, handleShareMailMerge],
  );

  const getUsersCountText = useMemo(() => {
    return t('share.noStatsModal.withUsers', { entity: capitalize(entity), length: size(selectedRows) });
  }, [t, entity, selectedRows]);

  useEffect(() => {
    const fetchTemplatesData = async () => {
      try {
        let smsData: TShareTemplates = [];
        let whatsappData: TShareTemplates = [];
        let emailData: TShareTemplates = [];

        const [whatsappRequest, smsRequest, emailRequest] = await Promise.allSettled([
          getWhatsAppTemplates(org.id),
          getSmsTemplates(org.id),
          getEmailTemplates(org.id),
        ]);

        if (whatsappRequest.status === 'fulfilled') {
          whatsappData = whatsappRequest.value.data;
        }

        if (smsRequest.status === 'fulfilled') {
          smsData = smsRequest.value.data;
        }
        if (emailRequest.status === 'fulfilled') {
          emailData = emailRequest.value.data;
        }

        setModelTemplate({ whatsapp: whatsappData, sms: smsData, email: emailData });
      } catch (error) {
        console.error('Error fetching templates data:', error);
      }
    };

    fetchTemplatesData();
  }, [org.id]);

  const modalConfigs: TShareWithDMConfig = useMemo(
    () => ({
      [EIntegrations.WhatsApp]: { templates: modelTemplate?.whatsapp },
      [EIntegrations.SMS]: { templates: modelTemplate?.sms },
      [EIntegrations.Email]: { templates: modelTemplate?.email, shareableCoverUrl: shareableCoverUrl },
      [EIntegrations.Beekeeper]: { customMessage: BEEKEEPER_DEFAULT_MESSAGE_TEXT },
      [EIntegrations.Slack]: { customMessage: SLACK_DEFAULT_MESSAGE_TEXT },
      [EIntegrations.Teams]: { customMessage: TEAMS_DEFAULT_MESSAGE_TEXT },
    }),
    [modelTemplate, shareableCoverUrl],
  );

  return (
    <>
      <ChooseIntegrationModal
        isOpen={isChooseIntegrationModalOpen}
        onClose={closeChooseIntegrationModal}
        entity={entity}
        onChoose={handleChooseOption}
        isLoading={isMailMergeLoading}
      />

      {integration &&
        Object.keys(modalConfigs).map(
          (key: TAllowedDMShareIntegrations) =>
            integration === key && (
              <ShareWithDMModal
                key={key}
                isOpen={isShareWithIntegrationOpen}
                entity={entity}
                integration={integration}
                onClose={onCloseModal}
                sendedUsersOrGroupsText={getUsersCountText}
                shareableTitle={shareableTitle}
                shareablePath={shareablePath}
                onShare={handleShareWithDM}
                templates={modalConfigs[key].templates}
                shareableCoverUrl={modalConfigs[key].shareableCoverUrl}
                customMessage={modalConfigs[key].customMessage}
              />
            ),
        )}
    </>
  );
};

export default ShareFromTableManager;
