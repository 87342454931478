import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IRdgColumnVisibility } from '../../../types/reactDataGrid';

export function useTableColumns(columnsVisibility: IRdgColumnVisibility, orgDatas) {
  const { t } = useTranslation();
  return useMemo(
    () =>
      [
        {
          name: 'userName',
          header: t('tableColumns.name'),
          maxWidth: 170,
        },
        {
          name: 'employeeId',
          header: t('tableColumns.employeeId'),
          maxWidth: 90,
          visible: columnsVisibility.employeeId,
        },
        {
          name: 'content',
          header: t('tableColumns.content'),
          width: 300,
          visible: columnsVisibility.content,
        },
        {
          name: 'viewed',
          header: t('tableColumns.viewed'),
          width: 80,
          visible: columnsVisibility.view,
        },
        {
          name: 'answer',
          header: t('tableColumns.answer'),
          width: 80,
          visible: columnsVisibility.answer,
        },
        {
          name: 'selectedAnswer',
          header: t('tableColumns.selectedAnswer'),
          width: 110,
          visible: columnsVisibility.selectedAnswer,
        },
        {
          name: 'success',
          header: t('tableColumns.success'),
          width: 80,
          visible: columnsVisibility.success,
        },
        {
          name: 'viewDate',
          header: t('tableColumns.viewDate'),
          type: 'date',
          maxWidth: 300,
          visible: columnsVisibility.viewDate,
        },
        orgDatas[0]?.title && {
          name: 'data1',
          header: orgDatas[0].title,
          visible: columnsVisibility.data1,
        },
        orgDatas[1]?.title && {
          name: 'data2',
          header: orgDatas[1].title,
          visible: columnsVisibility.data2,
        },
        orgDatas[2]?.title && {
          name: 'data3',
          header: orgDatas[2].title,
          visible: columnsVisibility.data3,
        },
        orgDatas[3]?.title && {
          name: 'data4',
          header: orgDatas[3].title,
          visible: columnsVisibility.data4,
        },
        orgDatas[4]?.title && {
          name: 'data5',
          header: orgDatas[4].title,
          visible: columnsVisibility.data5,
        },
        orgDatas[5]?.title && {
          name: 'data6',
          header: orgDatas[5].title,
          visible: columnsVisibility.data6,
        },
        orgDatas[6]?.title && {
          name: 'data7',
          header: orgDatas[6].title,
          visible: columnsVisibility.data7,
        },
        orgDatas[7]?.title && {
          name: 'data8',
          header: orgDatas[7].title,
          visible: columnsVisibility.data8,
        },
      ].filter((_) => _),
    [t, columnsVisibility, orgDatas],
  );
}
