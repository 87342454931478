import { makeStyles } from '@material-ui/core';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import { ReactComponent as SendIcon } from '../../assets/icons/airplane.svg';
import { ReactComponent as CopyLinkIcon } from '../../assets/icons/copy-link.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as ExcelIcon } from '../../assets/icons/excel.svg';
import { ReactComponent as MSTeamsPurpleIcon } from '../../assets/icons/ms-teams-purple.svg';
import { ReactComponent as SlackIcon } from '../../assets/icons/slack.svg';
import { ReactComponent as SMSIcon } from '../../assets/icons/sms.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/icons/whatsapp.svg';
import BeekeeperIcon from '../../assets/images/beekeeper-logo.png';
import { Features, MAIL_MERGE_ORG_IDS } from '../../constants/constants';
import { currentUserSelector, selectCanSendMessages } from '../../features/auth/auth.selectors';
import { selectOrg } from '../../features/org/org.slice';
import gtmTrack from '../../services/googleTagManager/track';
import { IEntityType } from '../../types/entity';
import { EIntegrations, IIntegrationType } from '../../types/integration';
import Loader from '../LoginLoader';
import Modal from '../Modal';

interface IProps {
  isOpen: boolean;
  entity: IEntityType;
  onClose: (evt: any) => void;
  onChoose: (integration: IIntegrationType) => void;
  onCopy?: () => void;
  isLoading?: boolean;
}

function ChooseIntegrationModal({ isOpen, entity, onClose, onChoose, onCopy, isLoading }: IProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const canSendMessages = useSelector(selectCanSendMessages);
  const currentOrg = useSelector(selectOrg);
  const currentUser = useSelector(currentUserSelector);
  const { showNewMessages } = useIntercom();

  const userOrg = useMemo(() => {
    return currentUser.organizations.find((org) => org.id === currentOrg.id);
  }, [currentOrg.id, currentUser.organizations]);

  const handleCopyToClipboard = () => {
    onCopy();
    if (entity === 'bite') {
      gtmTrack('bites_bite_share_bite');
    }
  };

  const handleChooseTeams = useCallback(() => {
    onChoose(EIntegrations.Teams);
  }, [onChoose]);

  const handleSmsContactUs = () => {
    showNewMessages('Hello Bites, I am Interested in SMS Distribution');
  };

  const beekeeperIntegration = currentOrg.features && currentOrg.features.includes(Features.BEEKEEPER_DISTRIBUTION);

  return (
    <Modal
      title={t('share.optionsTitle', { entity: entity.toUpperCase() })}
      HeaderIcon={SendIcon}
      isOpen={isOpen}
      className={classes.modal}
      onClose={onClose}
      content={
        <div className={classes.content}>
          {isLoading ? (
            <Loader />
          ) : (
            <div className={classes.options}>
              {canSendMessages && !beekeeperIntegration ? (
                <div
                  className={classes.option}
                  onClick={() => onChoose(EIntegrations.WhatsApp)}
                  data-intercom-target='start-whatsapp-distribution-flow'
                >
                  <WhatsappIcon className={classes.optionIcon} />
                  <div className={classes.optionText}>{t('common.whatsApp')}</div>
                </div>
              ) : null}
              {canSendMessages &&
                currentOrg.features &&
                currentOrg.features.includes(Features.SLACK_DISTRIBUTION) &&
                currentOrg.features.includes(Features.SLACK_INTEGRATION) && (
                  <div
                    className={classes.option}
                    onClick={() => onChoose(EIntegrations.Slack)}
                    data-intercom-target='start-slack-distribution-flow'
                  >
                    <SlackIcon className={classes.optionIcon} />
                    <div className={classes.optionText}>{t('common.slack')}</div>
                  </div>
                )}
              {!canSendMessages ? null : currentOrg.features &&
                currentOrg.features.includes(Features.SMS_DISTRIBUTION) ? (
                <div
                  className={classes.option}
                  onClick={() => onChoose(EIntegrations.SMS)}
                  data-intercom-target='start-sms-distribution-flow'
                >
                  <SMSIcon className={classes.optionIcon} />
                  <div className={classes.optionText}>{t('common.sms')}</div>
                </div>
              ) : beekeeperIntegration ? null : (
                <div
                  className={classes.optionSemiBlocked}
                  onClick={handleSmsContactUs}
                  data-intercom-target='sms-distribution-flow-locked'
                >
                  <SMSIcon className={classes.optionIconBlocked} />
                  <div className={classes.optionTextBlocked}>
                    {t('common.sms')} <div className={classes.optionBlockedLabel}>{t('common.contactUs')}</div>
                  </div>
                </div>
              )}
              {canSendMessages && currentOrg.features && currentOrg.features.includes(Features.EMAIL_DISTRIBUTION) && (
                <div
                  className={classes.option}
                  onClick={() => onChoose(EIntegrations.Email)}
                  data-intercom-target='start-email-distribution-flow'
                >
                  <EmailIcon className={classes.optionIcon} />
                  <div className={classes.optionText}>{t('common.email')}</div>
                </div>
              )}
              {canSendMessages && MAIL_MERGE_ORG_IDS.includes(currentOrg.id) ? (
                <div
                  className={classes.option}
                  onClick={() => onChoose(EIntegrations.Mail_merge)}
                  data-intercom-target='start-mail-merge-distribution-flow'
                >
                  <ExcelIcon className={classes.optionIcon} />
                  <div className={classes.optionText}>{t('common.mailMerge')}</div>
                </div>
              ) : null}
              {userOrg.isTeamsRelated && canSendMessages && (
                <div
                  className={classes.option}
                  onClick={handleChooseTeams}
                  data-intercom-target='start-teams-distribution-flow'
                >
                  <MSTeamsPurpleIcon className={classes.optionIcon} />
                  <div className={classes.optionText}>{t('common.teams')}</div>
                </div>
              )}
              {canSendMessages && currentOrg.features && beekeeperIntegration && (
                <div
                  className={classes.option}
                  onClick={() => onChoose(EIntegrations.Beekeeper)}
                  data-intercom-target='start-beekeeper-distribution-flow'
                >
                  <img src={BeekeeperIcon} className={classes.rectangleOptionIcon} alt='Beekeeper-logo' />
                  <div className={classes.optionText}>{t('common.beekeeper')}</div>
                </div>
              )}
              {onCopy && (
                <div
                  className={classes.option}
                  onClick={handleCopyToClipboard}
                  data-intercom-target='start-copy-to-clipboard-distribution-flow'
                >
                  <CopyLinkIcon className={classes.optionIcon} />
                  <div className={classes.optionText}>{t('share.copyLink')}</div>
                </div>
              )}
            </div>
          )}
        </div>
      }
    />
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    minWidth: 300,
    maxWidth: 500,
  },
  content: {
    padding: '40px 0',
  },
  options: {
    margin: '0 40px',
    width: 'fit-content',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:not(:last-child)': {
      marginBottom: 29,
    },
  },
  optionBlocked: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
    '&:not(:last-child)': {
      marginBottom: 29,
    },
  },
  optionSemiBlocked: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:not(:last-child)': {
      marginBottom: 29,
    },
  },
  rectangleOptionIcon: {
    marginInlineEnd: '5px',
    width: 23,
  },
  optionIcon: {
    marginInlineEnd: '5px',
    width: 23,
    height: 23,
  },
  optionIconBlocked: {
    marginInlineEnd: '5px',
    width: 23,
    height: 23,
    opacity: 0.5,
  },
  optionText: {
    fontSize: 19,
    color: '#1253f7',
  },
  optionTextBlocked: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 19,
    color: '#989898',
  },
  optionBlockedLabel: {
    marginLeft: 4,
    padding: '2px 6px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: 14,
    borderRadius: 4,
  },
}));

export default ChooseIntegrationModal;
