import { createSlice } from '@reduxjs/toolkit';

import { IFullPlaylist, ILeanPlaylist } from '../../types/playlist';
import { IRootState } from '../../types/store';
import { mergeLeanAndFullCollections } from '../../utils/collections';

const playlistsSlice = createSlice({
  name: 'playlists',
  initialState: getInitialState(),
  extraReducers: getExtraReducers(),
  reducers: getReducers(),
});

export const {
  fetchPlaylists,
  fetchLeanPlaylistsSuccess,
  fetchFullPlaylistsSuccess,
  fetchPlaylistsError,
  setPlaylistMap,
} = playlistsSlice.actions;

export default playlistsSlice.reducer;

export const selectPlaylists = (state: IRootState) => state.playlists;
export const playlistsMapSelector = (state: IRootState) => state.playlists.playlistsMap;

function getInitialState() {
  return {
    isFetching: false,
    error: '',
    playlists: [] as (ILeanPlaylist | IFullPlaylist)[],
    playlistsMap: {},
    fetched: false,
  };
}

type IPlaylistsState = ReturnType<typeof getInitialState>;

function getExtraReducers() {
  return {
    'auth/logout': () => getInitialState(),
  };
}

function getReducers() {
  return {
    fetchPlaylists: (state: IPlaylistsState) => {
      state.isFetching = true;
      state.error = '';
      state.fetched = true;
    },
    fetchLeanPlaylistsSuccess: (state: IPlaylistsState, action) => {
      const leanPlaylists: ILeanPlaylist[] = action.payload;
      state.isFetching = false;

      const playlistsMap = leanPlaylists.reduce((acc, playlist) => {
        acc[playlist.id] = playlist;
        return acc;
      }, {});

      state.playlistsMap = { ...state.playlistsMap, playlistsMap };
      state.playlists = mergeLeanAndFullCollections<ILeanPlaylist, ILeanPlaylist | IFullPlaylist>(
        leanPlaylists,
        state.playlists,
      );
    },
    fetchFullPlaylistsSuccess: (state: IPlaylistsState, action) => {
      state.isFetching = false;

      const playlistsMap = action.payload.reduce((acc, playlist) => {
        acc[playlist.id] = playlist;
        return acc;
      }, {});

      state.playlistsMap = { ...state.playlistsMap, playlistsMap };
      state.playlists = action.payload;
    },
    fetchPlaylistsError: (state: IPlaylistsState, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },

    setPlaylistMap(state: IPlaylistsState, action) {
      state.playlistsMap = { ...state.playlistsMap, ...action.payload };
    },
  };
}
