import { createAction, createSlice } from '@reduxjs/toolkit';

import { IBiteShare, IFullBite, ILeanBite } from '../../types/bite';
import { IRootState } from '../../types/store';
import { mergeMapWithArrayById, mergeLeanAndFullCollections } from '../../utils/collections';

const bitesSlice = createSlice({
  name: 'bites',
  initialState: getInitialState(),
  extraReducers: getExtraReducers(),
  reducers: getReducers(),
});

export const {
  fetchBites,
  fetchLeanBitesSuccess,
  fetchFullBitesSuccess,
  fetchBitesError,
  setBitesMap,
  setBiteSharesMap,
} = bitesSlice.actions;

export default bitesSlice.reducer;

export const selectBites = (state: IRootState) => state.bites;
export const bitesMapSelector = (state: IRootState) => state.bites.bitesMap;
export const biteSharesMapSelector = (state: IRootState) => state.bites.biteSharesMap;

export const fetchBitesData = createAction('bites/fetchBitesData', (bitesIds: number[]) => ({
  payload: bitesIds,
}));

function getInitialState() {
  return {
    isFetching: false,
    error: '',
    bites: [] as (ILeanBite | IFullBite)[],
    bitesMap: {} as { [key: string]: ILeanBite | IFullBite },
    biteSharesMap: {} as { [key: string]: IBiteShare },
  };
}

type IBitesState = ReturnType<typeof getInitialState>;

function getExtraReducers() {
  return {
    'auth/logout': () => getInitialState(),
  };
}
function getReducers() {
  return {
    fetchBites: (state: IBitesState) => {
      state.isFetching = true;
      state.error = '';
      state.bites = [];
    },
    fetchLeanBitesSuccess: (state: IBitesState, action) => {
      const leanBites: ILeanBite[] = action.payload;
      state.isFetching = false;
      state.bites = mergeLeanAndFullCollections<ILeanBite, ILeanBite | IFullBite>(leanBites, state.bites);
      state.bitesMap = mergeMapWithArrayById(state.bitesMap, leanBites);
    },
    fetchFullBitesSuccess: (state: IBitesState, action) => {
      const fullBites: IFullBite[] = action.payload;
      state.isFetching = false;
      state.bites = mergeLeanAndFullCollections<ILeanBite | IFullBite, IFullBite>(state.bites, fullBites);
      state.bitesMap = mergeMapWithArrayById(state.bitesMap, fullBites);
    },
    fetchBitesError: (state: IBitesState, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
    setBitesMap(state: IBitesState, action) {
      state.bitesMap = mergeMapWithArrayById(state.bitesMap, action.payload);
    },
    setBiteSharesMap(state: IBitesState, action) {
      state.biteSharesMap = mergeMapWithArrayById(state.biteSharesMap, action.payload, 'biteShareId');
    },
  };
}
