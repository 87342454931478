export enum EIntegrations {
  WhatsApp = 'whatsapp',
  Teams = 'teams',
  SMS = 'sms',
  Mail_merge = 'mail_merge',
  Email = 'email',
  Beekeeper = 'beekeeper',
  Slack = 'slack',
}

export type IIntegrationType = EIntegrations;

export type TAllowedDMShareIntegrations =
  | EIntegrations.WhatsApp
  | EIntegrations.SMS
  | EIntegrations.Email
  | EIntegrations.Beekeeper
  | EIntegrations.Slack
  | EIntegrations.Teams;
