import { TypeColumn } from '@inovua/reactdatagrid-community/types/TypeColumn';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { isArray } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import BlueButton from '../../components/Buttons/BlueButton';
import MainScreen from '../../components/MainScreen';
import MainScreenCard from '../../components/MainScreenCard';
import { REACT_DATA_GRID_LICENSE_KEY } from '../../constants/constants';

import { attributeValuesMapSelector } from './assignmentConfig.selectors';
import { getAttributeValues, getFeedContent, searchConfigs } from './assignmentConfig.slice';
import ContentLink from './common/ContentLink';
import UpdateConfigModal from './common/UpdateConfigModal';

function AssignmentConfigScreen() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tableRef = useRef(null);
  const tableCountRef = useRef(0);
  const currentDataRef = useRef([]);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  useEffect(() => {
    dispatch(getAttributeValues());
    dispatch(getFeedContent());
  }, [dispatch]);

  const handleOpenCreateModal = useCallback(() => {
    setIsModalOpened(true);
  }, []);

  const handleCloseCreateModal = useCallback(() => {
    setSelectedRowData(null);
    setIsModalOpened(false);
  }, []);

  const columns = useMemo((): TypeColumn[] => {
    const targetAttributes = ({
      data: {
        assignmentAttributesMapping: { attributeValueIds },
      },
    }) => {
      if (!attributeValueIds?.length) {
        return t('assignmentConfig.table.allUsers');
      }

      return <AttributeValuesList attributeValueIds={attributeValueIds} />;
    };
    const excludedAttributes = ({
      data: {
        assignmentAttributesMapping: { exceptAttributeValueIds },
      },
    }) => {
      if (!exceptAttributeValueIds?.length) {
        return t('assignmentConfig.table.noExcludedUsers');
      }
      return <AttributeValuesList attributeValueIds={exceptAttributeValueIds} />;
    };

    const contentLink = ({
      data: {
        assignmentAttributesMapping: { content },
      },
    }) => {
      return <ContentLink content={content} />;
    };

    return [
      {
        name: 'target',
        header: t('assignmentConfig.table.targetAttributeValues'),
        defaultFlex: 1,
        render: targetAttributes,
      },
      {
        name: 'excluded',
        header: t('assignmentConfig.table.exceptAttributeValues'),
        defaultFlex: 1,
        render: excludedAttributes,
      },
      {
        name: 'relatedContent',
        type: 'string',
        header: t('assignmentConfig.table.relatedContent'),
        defaultFlex: 1,
        render: contentLink,
      },
    ];
  }, [t]);

  const dataSource = useCallback(
    ({ skip, limit }: { skip: number; limit: number }): Promise<{ data: any; count: number }> =>
      new Promise((resolve) => {
        dispatch(
          searchConfigs({
            page: skip / limit,
            pageSize: limit,
            onSuccess: ({ data, count }) => {
              tableCountRef.current = count;
              currentDataRef.current = data;
              resolve({ data, count });
            },
            onError: (error: Error) => {
              toast.error(error.message);
              resolve({ data: [], count: tableCountRef.current });
            },
          }),
        );
      }),
    [dispatch],
  );

  const handleTableReady = useCallback((ref) => {
    tableRef.current = ref.current;
  }, []);

  const handleReloadTable = useCallback(() => {
    tableRef.current?.reload();
  }, []);

  const handleRowClick = useCallback((row) => {
    setSelectedRowData(row.data.assignmentAttributesMapping);
    setIsModalOpened(true);
  }, []);

  return (
    <S.Container>
      <MainScreen headline={t('assignmentConfig.title')}>
        <MainScreenCard>
          <S.Toggle>
            <BlueButton isSmall onClick={handleOpenCreateModal}>
              {t('assignmentConfig.createConfig')}
            </BlueButton>
          </S.Toggle>
          <ReactDataGrid
            rowStyle={S.RowStyles}
            onReady={handleTableReady}
            dataSource={dataSource}
            columns={columns}
            pagination
            defaultLimit={25}
            rowHeight={null}
            licenseKey={REACT_DATA_GRID_LICENSE_KEY}
            scrollThreshold='0.01'
            onRowClick={handleRowClick}
          />
        </MainScreenCard>
      </MainScreen>
      <UpdateConfigModal
        isOpen={isModalOpened}
        onReloadTable={handleReloadTable}
        onClose={handleCloseCreateModal}
        selectedRowData={selectedRowData}
      />
    </S.Container>
  );
}

const AttributeValuesList = ({ attributeValueIds }) => {
  const { t } = useTranslation();
  const attributeValuesMap = useSelector(attributeValuesMapSelector);
  return (
    <S.AttributeValuesList>
      {attributeValueIds.map((attributeValueId, index) => (
        <S.AttributeValue key={index}>
          {'- '}
          {isArray(attributeValueId)
            ? attributeValueId
                .map(
                  (attribute) =>
                    attributeValuesMap[attribute]?.name || t('assignmentConfig.table.attributeValueNotExists'),
                )
                .join(', ')
            : attributeValuesMap[attributeValueId]?.name || t('assignmentConfig.table.attributeValueNotExists')}
        </S.AttributeValue>
      ))}
    </S.AttributeValuesList>
  );
};

const S = {
  Container: styled.div`
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  `,
  Toggle: styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  `,
  AttributeValuesList: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  AttributeValue: styled.div`
    font-size: 14px;
  `,
  RowStyles: { cursor: 'pointer' },
};

export default AssignmentConfigScreen;
