import { TypeColumn } from '@inovua/reactdatagrid-community/types/TypeColumn';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import BlueButton from '../../components/Buttons/BlueButton';
import EllipsisWithTooltip from '../../components/EllipsisWithTooltip';
import MainScreen from '../../components/MainScreen';
import MainScreenCard from '../../components/MainScreenCard';
import { REACT_DATA_GRID_LICENSE_KEY } from '../../constants/constants';
import { bitesMapSelector } from '../bites/bites.slice';

import { fetchAskAiPromptHistory } from './askAiReports.actions';

function AskAiReportsScreen() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tableRef = useRef(null);
  const tableCountRef = useRef(0);
  const currentDataRef = useRef([]);
  const isShowOriginalRef = useRef(true);

  const [isShowOriginal, setIsShowOriginal] = useState(true);

  const classes = useStyles();

  const handleToggle = useCallback(() => {
    setIsShowOriginal((prev) => !prev);
    tableRef.current?.reload();
  }, []);

  const columns = useMemo((): TypeColumn[] => {
    const Name = ({ data: { user } }) => <div>{user.firstName ? `${user.firstName} ${user.lastName}` : user.id}</div>;
    const QA = ({ data: { historyItem } }) => (
      <div>
        {isShowOriginal
          ? `${t('askAiReports.table.q')} ${historyItem.prompt}\n${t('askAiReports.table.a')} ${
              historyItem.translatedAnswer || t('askAiReports.table.emptyAnswer')
            }`
          : `${t('askAiReports.table.q')} ${historyItem.promptEn}\n${t('askAiReports.table.a')} ${
              historyItem.answer || t('askAiReports.table.emptyAnswer')
            }`}
      </div>
    );

    return [
      {
        name: 'user',
        header: t('askAiReports.table.user'),
        defaultFlex: 1,
        render: Name,
      },
      {
        name: 'qa',
        type: 'string',
        header: t('askAiReports.table.qa'),
        defaultFlex: 2,
        render: QA,
      },
      {
        name: 'relatedContent',
        type: 'string',
        header: t('askAiReports.table.relatedBites'),
        defaultFlex: 1,
        render: BiteLink,
      },
    ];
  }, [isShowOriginal, t]);

  const dataSource = useCallback(
    ({ skip, limit }: { skip: number; limit: number }): Promise<{ data: any; count: number }> => {
      if (isShowOriginalRef.current !== isShowOriginal) {
        isShowOriginalRef.current = isShowOriginal;
        return new Promise((resolve) => {
          resolve({ data: [...currentDataRef.current], count: tableCountRef.current });
        });
      }

      return new Promise((resolve) => {
        dispatch(
          fetchAskAiPromptHistory({
            page: skip / limit,
            pageSize: limit,
            onSuccess: ({ data, count }) => {
              tableCountRef.current = count;
              currentDataRef.current = data;
              resolve({ data, count });
            },
            onError: (error: Error) => {
              toast.error(error.message);
              resolve({ data: [], count: tableCountRef.current });
            },
          }),
        );
      });
    },
    [dispatch, isShowOriginal],
  );

  const handleTableReady = useCallback((ref) => {
    tableRef.current = ref.current;
  }, []);

  return (
    <div className={classes.root}>
      <MainScreen headline={t('askAiReports.title')}>
        <MainScreenCard>
          <div className={classes.toggle}>
            <BlueButton isSmall onClick={handleToggle}>
              {t(`askAiReports.${isShowOriginal ? 'showTranslated' : 'showOriginal'}`)}
            </BlueButton>
          </div>
          <ReactDataGrid
            onReady={handleTableReady}
            dataSource={dataSource}
            columns={columns}
            pagination
            defaultLimit={25}
            rowHeight={null}
            licenseKey={REACT_DATA_GRID_LICENSE_KEY}
            scrollThreshold='0.01'
          />
        </MainScreenCard>
      </MainScreen>
    </div>
  );
}

const BiteLink = ({ data: { historyItem } }) => {
  const allBitesMap = useSelector(bitesMapSelector);
  const relatedContent = historyItem.answer ? historyItem.relatedContent : historyItem.searchRelatedContent;

  if (!relatedContent) {
    return null;
  }

  return relatedContent.map((content) => {
    const biteData = allBitesMap[content.biteId];
    return biteData ? (
      <Link key={`related-${biteData.id}`} to={`/bites/${biteData.id}`}>
        <EllipsisWithTooltip text={biteData.subject} />
      </Link>
    ) : null;
  });
};

const useStyles = makeStyles({
  root: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  toggle: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 16,
  },
});

export default AskAiReportsScreen;
